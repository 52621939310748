import React, { useContext } from 'react';
import styled from 'styled-components';

import { STYLES } from '../enums';
import { BrowserContext } from '../contexts/browser';
import { CoursesContext } from '../contexts/courses';
import CourseItem from '../components/course/courseItem';
import VideoPlayer from '../components/player/player';
import Stack from '../components/systems/stack';
import Grid from '../components/systems/grid';
import SEO from '../components/layout/seo';

const LandingView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const WelcomeText = styled.div`
  max-width: 500px;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    max-width: 90vw;
    margin-top: 50px;
  }
`;

const VideoContainer = styled.div`
  margin-top: 10px;
  width: 35vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 90vw;
  }
`;

const CoursesPage = () => {
  const { isPad } = useContext(BrowserContext);
  const { courses } = useContext(CoursesContext);

  return (
    <>
      <SEO title="Seminare" />
      <Stack gap="40px" centered={false}>
        <LandingView>
          <WelcomeText>
            <Stack gap="40px" collapseX>
              <h1>Vorstellung der verschiedenen Entspannungstechniken</h1>
              <p>
                Hier möchte ich Ihnen gerne die Unterschiede und Gemeinsamkeiten
                von Selbst-Hypnose, Autogenem Training und der Progressiven
                Muskelrelaxation erklären, damit Sie besser entscheiden können,
                welche Technik zu Ihnen passt.
              </p>
            </Stack>
          </WelcomeText>
          <VideoContainer>
            <VideoPlayer
              index={0}
              link="https://vimeo.com/450879617"
              latestProgress={0}
              widthPercentage={isPad ? 0.9 : 0.35}
            />
          </VideoContainer>
        </LandingView>
        <h1>Seminare</h1>
        <Grid gap="40px">
          {courses.map(course => (
            <CourseItem
              key={course.id}
              title={course.title}
              description={course.description}
              duration={course.duration}
              src={course.imageUrl}
              alt={course.imageText}
              link={course.link}
            />
          ))}
        </Grid>
      </Stack>
    </>
  );
};

export default CoursesPage;
