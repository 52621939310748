import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { STYLES } from '../../enums';
import CustomButton from '../clickables/customButton';
import Image from '../image/image';
import Stack from '../systems/stack';

const ImgContainer = styled.div`
  width: 28vw;

  @media screen and (max-width: ${STYLES.breakpoints.phoneWidth}px) {
    width: 90vw;
  }

  &:focus {
    transform: scale(1.003);
  }

  &:hover {
    transform: scale(1.003);
  }
`;

const CourseItem = ({ title, description, src, alt, link }) => (
  <Stack centered={false} gap="20px">
    <CustomButton
      label={`Navigate to ${title}`}
      title={`Navigate to ${title}`}
      onClick={() => navigate(`/${link}`)}
    >
      <ImgContainer>
        <Image src={src} alt={alt} />
      </ImgContainer>
    </CustomButton>
    <h1>{title}</h1>
    <p>{description}</p>
  </Stack>
);

CourseItem.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CourseItem;
